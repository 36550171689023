import React, { useEffect, useState } from "react";

export const ApiDoc = () => {
  const MultiToken = localStorage.getItem("MultiToken");
  const [allData, setallData] = useState({
    apikey: "",
    whitelistip: "",
    callbackurl: "",
    password: "",
    MultiToken: MultiToken,
  });

  useEffect(() => {
    if (MultiToken !== "" && MultiToken !== null) {
      getData();
    }
  }, []);
  const handleChange = (e) => {
    setallData({
      ...allData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const getData = async () => {
    const json = JSON.stringify({ MultiToken });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/multi/API/getData",
      requestOptions
    );
    const rspns = await req.json();
    if (rspns.Status) {
      setallData({
        apikey: rspns.Result[0].API_KEY,
        whitelistip: rspns.Result[0].IP,
        callbackurl: rspns.Result[0].CALLBACK,
        password: "",
        MultiToken: MultiToken,
      });
    } else {
      alert(rspns.Msg);
    }
  };

  const updateData = async (e) => {
    e.preventDefault();
    const json = JSON.stringify(allData);
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/multi/API/UpdateData",
      requestOptions
    );
    const rspns = await req.json();
    if (rspns.Status) {
      alert(rspns.Msg);
      getData();
    } else {
      alert(rspns.Msg);
    }
  };

  return (
    <>
      <div className="page-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Make Sure !! Do You Want To Integrate API</h5>
              </div>
              <div className="card-block">
                <div className="container">
                  <h4 className="pb-3"> Api Documentation</h4>
                  <form action="" method="post" autoComplete="off">
                    <div className="form-group row mt-3">
                      <div className="col-sm-6">
                        <label htmlFor="">Api Key</label>
                        <input
                          type="text"
                          defaultValue={allData.apikey}
                          readOnly
                          className="form-control"
                        />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="">Mobile Parameter</label>
                        <input
                          type="text"
                          defaultValue="mobile"
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row mt-3">
                      <div className="col-sm-4">
                        <label htmlFor="">Operator Parameter</label>
                        <input
                          type="text"
                          defaultValue="operator"
                          readOnly
                          className="form-control"
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">Amount Parameter</label>
                        <input
                          type="text"
                          defaultValue="amount"
                          readOnly
                          className="form-control"
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">Recharge Type Parameter</label>
                        <input
                          type="text"
                          defaultValue="rctype"
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row mt-3">
                      <div className="col-sm-4">
                        <label htmlFor="">Enter Whitelist IP</label>
                        <input
                          type="text"
                          name="whitelistip"
                          autoComplete="off"
                          required=""
                          onChange={handleChange}
                          value={allData.whitelistip}
                          className="form-control"
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">Enter Callback URL</label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="callbackurl"
                          required=""
                          onChange={handleChange}
                          value={allData.callbackurl}
                          className="form-control"
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">Enter Password</label>
                        <input
                          type="password"
                          autoComplete="off"
                          name="password"
                          required=""
                          onChange={handleChange}
                          value={allData.password}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <input
                        type="submit"
                        className="btn btn-primary"
                        onClick={updateData}
                        value="Save"
                      />
                    </div>
                  </form>
                  <h4>API HIT URL TYPE : </h4>
                  <p>
                    https://api1.limba.in/Backend/multi/API/addRecharge?api_key=&lt;
                    YOUR API KEY &gt;&amp;mobile=&lt; YOUR MOBILE
                    &gt;&amp;amount=&lt; AMOUNT &gt;&amp;operator=&lt; YOUR
                    OPERATOR &gt;&amp;rctype= &lt; RECHARGE TYPE
                    &gt;&amp;order_id=&lt; Your Order ID &gt;
                  </p>
                  <h4>API HIT EXAMPLE: </h4>
                  <p>
                    https://api1.limba.in/Backend/multi/API/addRecharge?api_key=APIKEY&amp;mobile=123456789&amp;amount=10&amp;operator=Airtel&amp;rctype=Prepaid&amp;order_id=12345
                  </p>
                  <h4>SAMPLE :</h4> <br />
                  <h4>API HIT TYPE : GET</h4> <br />
                  <h4>API RESPONSE FORMAT : JSON</h4>
                  <br />
                  <h4>rcstatus : Recharge Staus</h4>
                  <h4>ERROR CODE 200 : SUCCESS</h4>
                  <h4>ERROR CODE 500 : Contact to Admin</h4>
                  <h4>ERROR CODE 404 : User not found</h4>
                  <h4>ERROR CODE 403 : Putt All Parameters</h4>
                  <br />
                  <hr />
                  <br />
                  <h4>Callback Type : </h4>
                  <p>
                    &lt; Your Callback URL &gt;?order_id=&lt; order id
                    &gt;&amp;status=&lt; Status &gt;&amp;op_id=&lt; Operator ID
                    &gt;&amp;number=&lt; Number &gt;
                  </p>
                  <h4>Callback HIT TYPE : GET</h4> <br />
                  <h4>
                    order_id : This is order id which you pass during api hit
                  </h4>{" "}
                  <br />
                  <h4>Status : Status will be SUCESS or FAILED</h4> <br />
                  <h4>
                    op_id : This is Operator Id which you get when Recharge
                    SUCCESS
                  </h4>{" "}
                  <br />
                  <h4>
                    number : This is Number on which recharge Process.{" "}
                  </h4>{" "}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
