import React, { useEffect, useState } from "react";

export const RtApiDoc = () => {
  return (
    <>
      <div className="page-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Make Sure !! Do You Want To Integrate API</h5>
              </div>
              <div className="card-block">
                <div className="container">
                  <h4 className="pb-3"> Api Documentation</h4>
                  <h4>API HIT URL TYPE TO GET RECHARGE : </h4>
                  <p>https://api1.limba.in/Backend/multi/API/listRecharge</p>
                  <h4>SAMPLE :</h4> <br />
                  <h4>API HIT TYPE : GET</h4> <br />
                  <h4>API RESPONSE FORMAT : JSON</h4>
                  <br />
                  <h4>rcstatus : Recharge Staus</h4>
                  <h4>ERROR CODE 200 : SUCCESS</h4>
                  <h4>ERROR CODE 500 : Contact to Admin</h4>
                  <h4>ERROR CODE 404 : User not found</h4>
                  <h4>ERROR CODE 403 : Putt All Parameters</h4>
                  <br />
                  <hr />
                  <br />
                  <h4>Callback Type : </h4>
                  <p>
                    {/* https://api1.limba.in/Backend/retailer/UpdateRech?txnid=3&status=success&opid=test123 */}
                    &lt; https://api1.limba.in/Backend/retailer/UpdateRech
                    &gt;?txnid=&lt; rechage id &gt;&amp;status=&lt; Status
                    &gt;&amp;op_id=&lt; Operator ID &gt;
                  </p>
                  <h4>Sample Callback URL : </h4>
                  <p>
                    https://api1.limba.in/Backend/retailer/UpdateRech?txnid=3&status=success&opid=test123
                    <br />
                    https://api1.limba.in/Backend/retailer/UpdateRech?txnid=3&status=failed&opid=test123
                  </p>
                  <h4>Callback HIT TYPE : GET</h4> <br />
                  <h4>
                    txnid : This is id which you got during list recharge as ID
                    param
                  </h4>
                  <br />
                  <h4>status : Status will be SUCESS or FAILED</h4> <br />
                  <h4>
                    op_id : This is Operator Id which you get when Recharge
                    SUCCESS
                  </h4>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
