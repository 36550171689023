import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../../assets/css/style.css";
import logo from "../../../assets/img/logo.png";
import avater from "../../../assets/images/avatar-1.png";

export const TopHeader = () => {
  const MultiToken = localStorage.getItem("MultiToken");
  const navigate = useNavigate();
  const [multiinfo, setmultiinfo] = useState({
    name: "",
    bal: "",
  });
  useEffect(() => {
    if (MultiToken !== "" && MultiToken !== null) {
      getData();
      let checkdata = setInterval(() => {
        getData();
      }, 60000);

      return () => clearInterval(checkdata);
    } else {
      navigate("/multilogin");
    }
  }, []);
  const logout = () => {
    localStorage.setItem("MultiToken", "");
    navigate("/multilogin");
  };

  const getData = async () => {
    const json = JSON.stringify({ MultiToken });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/multi/multiinfo/getInfo",
      requestOptions
    );
    const rspns = await req.json();
    if (rspns.Status) {
      setmultiinfo({
        name: rspns.Result[0].NAME,
        bal: rspns.Result[0].RCBAL,
      });
    } else {
      // alert(rspns.Msg)
      navigate("/multilogin");
    }
  };
  return (
    <>
      <nav
        className="navbar header-navbar pcoded-header"
        style={{ zIndex: "11111" }}
        header-theme="theme4"
      >
        <div
          className="navbar-wrapper position-fixed "
          style={{ width: "100%" }}
        >
          <div className="navbar-logo">
            <p className="mobile-menu" id="mobile-collapse">
              <i className="ti-menu"></i>
            </p>
            <p className="mobile-search morphsearch-search">
              <i className="ti-search"></i>
            </p>
            <Link
              style={{ fontSize: "18px", fontWeight: "700" }}
              to="/admin/dashboard"
            >
              <img
                className="img-fluid"
                src={logo}
                width="100"
                alt="CompanyLogo"
              />
            </Link>
            <p className="mobile-options">
              <i className="ti-more"></i>
            </p>
          </div>
          <div className="navbar-container container-fluid">
            <div>
              <ul className="nav-right" style={{ marginRight: "15rem" }}>
                <li className="header-notification">
                  <p className="displayChatbox text-light v-bal">
                    RC BAl: {multiinfo.bal}
                  </p>
                </li>

                <li className="user-profile header-notification">
                  <p>
                    <img src={avater} alt="User-Profile" />
                    <span className="text-light">{multiinfo.name}</span>
                    <i className="ti-angle-down"></i>
                  </p>
                  <ul className="show-notification profile-notification">
                    <li>
                      <p>
                        <i className="ti-settings"></i> Settings
                      </p>
                    </li>
                    <li>
                      <p onClick={logout}>
                        <i className="ti-layout-sidebar-left"></i> Logout
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
