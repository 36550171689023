import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const AdminToken = localStorage.getItem("AdminToken");
export const SetRetailerCom = () => {
  const { id } = useParams();
  const [formData, updateFormData] = React.useState({
    activepack: "",
    user: "retailer",
    userid: id,
    AdminToken: AdminToken,
  });
  useEffect(() => {
    getData();
  }, []);
  const [loading, setloading] = useState(false);

  const [packlist, setpacklist] = useState([]);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const getData = async () => {
    setloading(true);
    const json = JSON.stringify(formData);
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/admin/commission/fetchcompack",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    setloading(false);
    if (rspns.Status) {
      if (rspns.activepack != null) {
        updateFormData({
          activepack: rspns.activepack,
          user: "retailer",
          userid: id,
          AdminToken: AdminToken,
        });
      }
      if (rspns.Result != null) {
        setpacklist(rspns.Result);
      }
    } else {
      setpacklist([]);
      alert(rspns.Msg);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const json = JSON.stringify(formData);
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/admin/commission/updatecompack",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    setloading(false);
    if (rspns.Status) {
      alert(rspns.Msg);
      updateFormData({
        amount: "",
        comm: "",
        userid: id,
        AdminToken: AdminToken,
      });
      getData();
    } else {
      alert(rspns.Msg);
    }
  };
  return (
    <>
      <div className="main-body">
        <div className="page-wrapper">
          <div className="page-header">
            <div className="page-header-title">
              <h4>Set package::</h4>
            </div>
            <div className="page-header-breadcrumb">
              <ul className="breadcrumb-title">
                <li className="breadcrumb-item">
                  <a href="index-2.html">
                    <i className="icofont icofont-home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="settings.php">CRM Managment</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="settings.php">Set Package</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="page-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Make Sure !! Do You Want To Change Package::</h5>
                    <code>Change</code> Your Pckage
                    <div className="card-header-right">
                      <i className="icofont icofont-rounded-down"></i>
                      <i className="icofont icofont-refresh"></i>
                      <i className="icofont icofont-close-circled"></i>
                    </div>
                  </div>
                  <div className="card-block">
                    <div className="container">
                      <form action="" method="post" onSubmit={handleSubmit}>
                        <div className="form-group row  justify-content-center mt-3">
                          <div className="col-sm-8">
                            <select
                              name="activepack"
                              onChange={handleChange}
                              className="form-control"
                            >
                              <option value="" selected disabled>
                                ---- Select Package ----
                              </option>
                              {packlist.map((data, key) => {
                                return (
                                  <option
                                    key={key}
                                    selected={data.ID === formData.activepack}
                                    value={data.ID}
                                  >
                                    {data.PACKNAME}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-primary waves-effect waves-light m-r-20"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
