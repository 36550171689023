import React from "react";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Link } from 'react-router-dom';

const drawerWidth = 240;
const styles = {
  sideNav: {
    marginTop: '-60px',
    zIndex: 3,
    marginLeft: '0px',
    position: 'fixed',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  }
};
export const SideBar = () => {
  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          <Link to="/multi/Home"  style={styles.link}>
            <ListItem button key={"Dashboard"}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItem>
          </Link>
          <Link to="/multi/dashboard/ApiDoc"  style={styles.link}>
            <ListItem button key={"ApiDoc"}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"ApiDoc"} />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
        <Link to="/multi/dashboard/TodayReport"  style={styles.link}>
          <ListItem button key={"Today Report"}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
          <ListItemText primary={"Today Report"} />
        </ListItem>
          </Link>
        <Link to="/multi/dashboard/AllReport"  style={styles.link}>
        <ListItem button key={"All Reports"}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"All Reports "} />
        </ListItem>
        </Link>
        <Link to="/multi/dashboard/FundReport"  style={styles.link}>
        <ListItem button key={"Fund Reports"}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"Fund Reports "} />
        </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
      <Link to="/multi/dashboard/TdComplaints"  style={styles.link}>
        <ListItem button key={"TodayComplaints"}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"Today Complaints"} />
        </ListItem>
      </Link>
      <Link to="/multi/dashboard/AllComplaints"  style={styles.link}>
        <ListItem button key={"AllComplaints"}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={"All Complaints"} />
        </ListItem>
      </Link>
      </List>
    </Drawer >
    </>
  );
};
