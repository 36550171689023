import React from "react";
import MuiCard from "../components/MuiCard";

import { useEffect, useState } from "react";

import AllReportTable from "./components/AllReportTable";
import { Loading } from "./components/Loading";
import { ConvertExcel } from "../../makeExcel/ConvertExcel";

export const MultiAllReport = (props) => {
  const MultiToken = localStorage.getItem("MultiToken");
  const [page, setpage] = useState(0);
  const [rows, setrows] = useState([]);
  const [loading, setloading] = useState(true);
  const [refresh, setrefresh] = useState(false);
  let newDate = new Date();
  let date =
    newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`;
  let mnth =
    newDate.getMonth() + 1 < 10
      ? `0${newDate.getMonth() + 1}`
      : `${newDate.getMonth() + 1}`;
  let year = newDate.getFullYear();
  let fulldate = `${year}-${mnth}-${date}`;
  const [details, setdetails] = useState({
    success: 0,
    pending: 0,
    failed: 0,
    refunded: 0,
    refundedam: 0,
    successam: 0,
    failedam: 0,
    pendingam: 0,
  });
  const [filterData, updatefilterData] = React.useState({
    fdate: fulldate,
    tdate: fulldate,
    type: "",
    user: "",
    number: "",
    userid: "",
  });
  useEffect(() => {
    getData();
  }, [page]);

  const handleChange = (e) => {
    updatefilterData({
      ...filterData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    setpage(0);
    setrows([]);
    setdetails({
      success: 0,
      pending: 0,
      failed: 0,
      refunded: 0,
      refundedam: 0,
      successam: 0,
      failedam: 0,
      pendingam: 0,
    });
    getData();
  };

  const getData = async () => {
    // setpage(page + 1);
    setloading(true);
    const json = JSON.stringify({ MultiToken, page, filterData });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/multi/recharge/getData",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    if (rspns.Status) {
      if (rspns.Result !== null) {
        let total = Math.ceil(rspns.TotalResult / 5000) - 1;
        // console.log(total)
        if (page <= total && rspns.TotalResult > 5000) {
          setdetails({
            success: Number(details.success) + Number(rspns.success),
            pending: Number(details.pending) + Number(rspns.pending),
            failed: Number(details.failed) + Number(rspns.failed),
            refunded: Number(details.refunded) + Number(rspns.refunded),
            refundedam: Number(details.refundedam) + Number(rspns.refundedam),
            successam: Number(details.successam) + Number(rspns.successam),
            failedam: Number(details.failedam) + Number(rspns.failedam),
            pendingam: Number(details.pendingam) + Number(rspns.pendingam),
          });
          setrows(rows.concat(rspns.Result));
          if (page < total) {
            setpage(page + 1);
          } else {
            setloading(false);
          }
        } else {
          setdetails({
            success: Number(rspns.success),
            pending: Number(rspns.pending),
            failed: Number(rspns.failed),
            refunded: Number(rspns.refunded),
            refundedam: Number(rspns.refundedam),
            successam: Number(rspns.successam),
            failedam: Number(rspns.failedam),
            pendingam: Number(rspns.pendingam),
          });
          setrows(rspns.Result);
          setloading(false);
        }
      }
    } else {
      setloading(false);
      setrows([]);
      setdetails({
        success: 0,
        pending: 0,
        failed: 0,
        refunded: 0,
        refundedam: 0,
        successam: 0,
        failedam: 0,
        pendingam: 0,
      });
    }
  };

  if (refresh) {
    setloading(true);
    setpage(0);
    setrows([]);
    setdetails({
      success: 0,
      pending: 0,
      failed: 0,
      refunded: 0,
      refundedam: 0,
      successam: 0,
      failedam: 0,
      pendingam: 0,
    });
    getData();
    setrefresh(false);
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="container">
        <div className="row">
          <div className="col-md-3 my-1">
            <MuiCard maintext="Success" text={details.success} icon="bx:user" />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard maintext="Pending" text={details.pending} icon="bx:user" />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="Failed"
              text={details.failed}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="Refunded"
              text={details.refunded}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="S. Amount"
              text={details.successam}
              icon="bx:user"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="P. Amount"
              text={details.pendingam}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="F. Amount"
              text={details.failedam}
              icon="bx:user"
            />
          </div>
          <div className="col-md-3 my-1">
            <MuiCard
              maintext="R. Amount"
              text={details.refundedam}
              icon="bx:user"
            />
          </div>
        </div>
        <div className="card container mt-3 p-3">
          <form action="" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="">From Date</label>
                  <input
                    type="date"
                    name="fdate"
                    onChange={handleChange}
                    value={filterData.fdate}
                    required
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="">To Date</label>
                  <input
                    type="date"
                    name="tdate"
                    onChange={handleChange}
                    value={filterData.tdate}
                    required
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="">Number </label>
                  <input
                    type="number"
                    name="number"
                    onChange={handleChange}
                    value={filterData.number}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="">Type</label>
                  <select
                    name="type"
                    onChange={handleChange}
                    className="form-control stock"
                  >
                    <option value="" selected={filterData.type === ""}>
                      Type{" "}
                    </option>
                    <option
                      value="success"
                      selected={filterData.type === "success"}
                    >
                      success
                    </option>
                    <option
                      value="pending"
                      selected={filterData.type === "pending"}
                    >
                      pending
                    </option>
                    <option
                      value="failed"
                      selected={filterData.type === "failed"}
                    >
                      failed
                    </option>
                    <option
                      value="refunded"
                      selected={filterData.type === "refunded"}
                    >
                      refunded
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <button className="btn btn-primary">Submit </button>
              </div>
              <div className="col-6">
                <ConvertExcel data={rows} name="AllRechargeReport" />
              </div>
            </div>
          </form>
        </div>

        <AllReportTable
          data={rows}
          setloading={setloading}
          refresh={setrefresh}
        />
      </div>
    </>
  );
};
