import React from "react";
import { useState } from "react";

const AdminToken = localStorage.getItem("AdminToken");
export const FundTransfer = () => {
  const [userlist, setuserlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [formData, updateFormData] = React.useState({
    user: "",
    userid: "",
    type: "",
    remark: "",
    amount: "",
    AdminToken: AdminToken,
  });

  const fetchUser = async (e) => {
    handleChange(e);
    setloading(true);
    const user = e.target.value;
    const json = JSON.stringify({ AdminToken, user });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/admin/fund/fetchuser",
      requestOptions
    );
    const rspns = await req.json();

    setloading(false);
    if (rspns.Status) {
      if (rspns.Result !== null) {
        setuserlist(rspns.Result);
      }
    } else {
      setuserlist([]);
    }
  };
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const json = JSON.stringify(formData);
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/admin/fund/sendfund",
      requestOptions
    );
    const rspns = await req.json();
    setloading(false);
    if (rspns.Status) {
      updateFormData({
        user: "",
        userid: "",
        type: "",
        remark: "",
        amount: "",
        AdminToken: AdminToken,
      });
      alert(rspns.Msg);
    } else {
      // setuserlist([]);
      alert(rspns.Msg);
    }
  };

  return (
    <div className="container bg-light">
      <div className="card">
        <div className="card-header">
          <h5 className="card-header-text">Fund Transfer </h5>
        </div>
        <div className="card-block container">
          <div className="view-info">
            <div className="row">
              <div className="col-lg-12">
                <div className="general-info">
                  <div className="row">
                    <div className="col-lg-12">
                      <form method="post" onSubmit={handleSubmit}>
                        <div className="form-group row mt-3">
                          <div className="input-group col-sm-4">
                            <select
                              onChange={(e) => fetchUser(e)}
                              required
                              name="user"
                              className="form-control stock"
                            >
                              <option value="" selected={formData.user === ""}>
                                {" "}
                                Select User Type{" "}
                              </option>
                              <option
                                value="retailer"
                                selected={formData.user === "retailer"}
                              >
                                Retailer
                              </option>
                              <option
                                value="multi"
                                selected={formData.user === "multi"}
                              >
                                Multi Provider
                              </option>
                            </select>
                          </div>
                          <div className="input-group col-sm-4">
                            <select
                              name="userid"
                              required
                              onChange={handleChange}
                              className="form-control stock"
                            >
                              <option
                                value=""
                                selected={formData.userid === ""}
                              >
                                {" "}
                                Select User
                              </option>
                              {userlist.map((data, key) => {
                                return (
                                  <option
                                    value={data.ID}
                                    key={key}
                                    selected={formData.userid === data.ID}
                                  >
                                    {" "}
                                    {data.Name} ( {data.Mobile} )
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="input-group col-sm-4">
                            <select
                              name="type"
                              required
                              onChange={handleChange}
                              className="form-control stock"
                            >
                              <option value="" selected={formData.type === ""}>
                                Transaction Type{" "}
                              </option>
                              <option
                                value="credit"
                                selected={formData.type === "credit"}
                              >
                                Add Fund
                              </option>
                              <option
                                value="debit"
                                selected={formData.type === "debit"}
                              >
                                Deduct Fund
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row mt-3">
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={formData.amount}
                              required
                              className="form-control"
                              onChange={handleChange}
                              name="amount"
                              placeholder="Amount"
                            />
                          </div>
                          <div className="form-group col-sm-6">
                            <input
                              type="text"
                              value={formData.remark}
                              className="form-control"
                              onChange={handleChange}
                              name="remark"
                              placeholder="Remark"
                            />
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            disabled={loading}
                            onChange={handleChange}
                            className="btn btn-primary waves-effect waves-light m-r-20"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
