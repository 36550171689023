import React from "react";
import MuiCard from "../components/MuiCard";

import { useEffect, useState } from "react";

import FundTable from "./components/FundTable";
import { Loading } from "./components/Loading";
import { ConvertExcel } from "../../makeExcel/ConvertExcel";

export const MultiFundReport = (props) => {
  const MultiToken = localStorage.getItem("MultiToken");
  const [page, setpage] = useState(0);
  const [rows, setrows] = useState([]);
  const [loading, setloading] = useState(true);
  const [refresh, setrefresh] = useState(false);

  let newDate = new Date();
  let date =
    newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`;
  let mnth =
    newDate.getMonth() + 1 < 10
      ? `0${newDate.getMonth() + 1}`
      : `${newDate.getMonth() + 1}`;
  let year = newDate.getFullYear();
  let fulldate = `${year}-${mnth}-${date}`;
  const [details, setdetails] = useState({
    multitrans: 0,
    multitransam: 0,
  });
  const [filterData, updatefilterData] = React.useState({
    fdate: fulldate,
    tdate: fulldate,
    type: "",
    user: "",
    userid: "",
  });
  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    updatefilterData({
      ...filterData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    getData();
  };
  const getData = async () => {
    const json = JSON.stringify({ MultiToken, page, filterData });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/multi/fund/getData",
      requestOptions
    );
    const rspns = await req.json();
    console.log(rspns);
    if (rspns.Status) {
      setloading(false);
      setdetails({
        multitrans: rspns.MultiTxn,
        multitransam: rspns.MultiTxnBal,
      });
      if (rspns.Result !== null) {
        setrows(rspns.Result);
      }
    } else {
      setloading(false);
      setrows([]);
      setdetails({
        multitrans: 0,
        multitransam: 0,
      });
    }
  };
  return (
    <>
      <Loading loading={loading} />
      <div className="container">
        <div className="row">
          <div className="col-md-4 my-1">
            <MuiCard
              maintext="Total Trans."
              text={details.multitrans}
              icon="bx:user-check"
            />
          </div>
          <div className="col-md-4 my-1">
            <MuiCard
              maintext="Total Amount"
              text={details.multitransam}
              icon="bx:user"
            />
          </div>
        </div>
        <div className="card container mt-3 p-3">
          <form action="" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="">From Date</label>
                  <input
                    type="date"
                    name="fdate"
                    onChange={handleChange}
                    value={filterData.fdate}
                    required
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="">To Date</label>
                  <input
                    type="date"
                    name="tdate"
                    onChange={handleChange}
                    value={filterData.tdate}
                    required
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="">Txn Type</label>
                  <select
                    name="type"
                    onChange={handleChange}
                    className="form-control stock"
                  >
                    <option value="" selected={filterData.type === ""}>
                      Transaction Type{" "}
                    </option>
                    <option
                      value="credit"
                      selected={filterData.type === "credit"}
                    >
                      Add Fund
                    </option>
                    <option
                      value="debit"
                      selected={filterData.type === "debit"}
                    >
                      Deduct Fund
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <button className="btn btn-primary">Submit </button>
              </div>
              <div className="col-6">
                <ConvertExcel data={rows} name="FundReport" />
              </div>
            </div>
          </form>
        </div>
        <FundTable rows={rows} setloading={setloading} refresh={setrefresh} />
      </div>
    </>
  );
};
