import { React } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppState from "./context/AppState";
import routes from "./components/routes";
function App() {
  
  return (
    <AppState>
    <BrowserRouter>
      <Routes>
      {
        routes.map((item , index) =>{
            return <Route path={item.path} key={index} exact={item.exact} element={item.element}></Route>;
        })
      }
      </Routes>
    </BrowserRouter>
    </AppState>
  );
}
export default App;
