import React from 'react'
import { MainSide } from '../components/MainSide'
import { SideBar } from '../components/SideBar'
import { TopHeader } from '../components/TopHeader'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: "#c62828",
    },
  },
});

export const MultiHome = (props) => {
  return (
    <>

      <ThemeProvider theme={theme}>
        <TopHeader />
        <SideBar />
        <MainSide page={props.page}/>
      </ThemeProvider>
    </>
  )
}
