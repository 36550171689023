import { React, useState } from "react";
import StateContext from "./StateContext";


const AppState = (props) => {
    const appSt = {
        "login": false,
        "authToken": "",
        "userBal": "0",
        "userName": "",
        "avail": false
    };
    const [appState, setappState] = useState(appSt);
    // console.log(appState)
    return (
            <StateContext.Provider value={{appState , setappState}}>
                {props.children}
            </StateContext.Provider>
    );
};

export default AppState;