import { React, useState, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import StateContext from "../../context/StateContext";
import { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const changeAvailabilty = "changeAvailabilty";
function Navbar(props) {
  const Token = localStorage.getItem("token");

  const state = useContext(StateContext);
  const [avail, setavail] = useState(false);
  const { appState, setappState } = state;
  useEffect(() => {
    if (Token !== "" && Token !== null) {
      getData();
      setTimeout(() => {
        getData();
      }, 60000);
    }
  }, []);

  const changeAvail = async () => {
    const json = JSON.stringify({ Token, changeAvailabilty });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/retailer/changeAvail",
      requestOptions
    );
    const rspns = await req.json();
    // console.log(rspns);
    if (rspns.Status) {
      setavail(rspns.Avail);
      localStorage.setItem("avail", rspns.Avail);
      alert("Updated");
    } else {
      alert(rspns.Msg);
    }
  };
  const getData = async () => {
    const json = JSON.stringify({ Token: localStorage.getItem("token") });
    const requestOptions = {
      method: "POST",
      body: json,
    };
    const req = await fetch(
      "https://api1.limba.in/Backend/retailer/details",
      requestOptions
    );
    const rspns = await req.json();
    // console.log(rspns);
    if (rspns.Status) {
      setavail(rspns.Avail);
      setappState({
        userName: rspns.Name,
        avail: rspns.Avail,
        userBal: rspns.Bal,
      });
    } else {
      alert(rspns.Msg);
    }
  };
  return (
    <>
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Limba
          </Typography>
          <nav>
            <Link
              to="/retailer"
              variant="contained"
              style={{ margin: "auto 20px" }}
              sx={{ my: 1, mx: 3 }}
            >
              Home
            </Link>
            <Link
              to="/retailer/report"
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}
            >
              Report
            </Link>

            <Button
              onClick={props.logout}
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}
            >
              Logout
            </Button>
            <Button variant="button" sx={{ my: 1, mx: 1.5 }}>
              {appState.userName} ( {appState.userBal} )
            </Button>
          </nav>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={avail} onChange={changeAvail} />}
              label="Availablity"
            />
            {/* <FormControlLabel disabled control={<Switch />} label="Disabled" /> */}
          </FormGroup>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;
