import { React } from "react";
import Login from "./Login";
// import Register from "./components/Register";
import { Retailer } from "./retailer/Retailer";
import { Report } from "./retailer/Reports/Report";
import AdminLogin from "./admin/AdminLogin";
import { Home } from "./admin/dashboard/Home";
import { Dashboard } from "./admin/pages/Dashboard";
import { RetailerList } from "./admin/pages/RetailerList";
import { MultiList } from "./admin/pages/MultiList";
import { FundReport } from "./admin/pages/FundReport";
import { Complaints } from "./admin/pages/Complaints";
import { AllReport } from "./admin/pages/AllReport";
import { Commission } from "./admin/pages/Commission";
import { FundTransfer } from "./admin/pages/FundTransfer";
import { TodayReport } from "./admin/pages/TodayReport";
import { Setcom } from "./admin/pages/Setcom";
import { SetRetailerCom } from "./admin/pages/SetRetailerCom";
import { SetMultiCom } from "./admin/pages/SetMultiCom";
import MultiLogin from "./multi/MultiLogin";
import { MultiHome } from "./multi/dashboard/MultiHome";
import { MultiDashboard } from "./multi/pages/MultiDashboard";
import { MultiTodayReport } from "./multi/pages/MultiTodayReport";
import { MultiAllReport } from "./multi/pages/MultiAllReport";
import { MultiFundReport } from "./multi/pages/MultiFundReport";
import { MultiTdComplaints } from "./multi/pages/MultiTdComplaints";
import { MultiAllComplain } from "./multi/pages/MultiAllComplain";
import { AllComplain } from "./admin/pages/AllComplain";
import { TdComplaints } from "./admin/pages/TdComplaints";
import { ApiDoc } from "./multi/pages/ApiDoc";
import { RtApiDoc } from "./retailer/RtApiDoc";

const routes = [
  {
    path: "admin/dashboard/",
    exact: true,
    element: <Home page={<Dashboard />} />,
  },
  {
    path: "admin/dashboard/RetailerList",
    exact: true,
    element: <Home page={<RetailerList />} />,
  },
  {
    path: "admin/dashboard/MultiList",
    exact: true,
    element: <Home page={<MultiList />} />,
  },
  {
    path: "admin/dashboard/FundReport",
    exact: true,
    element: <Home page={<FundReport />} />,
  },
  {
    path: "admin/dashboard/Complaints",
    exact: true,
    element: <Home page={<Complaints />} />,
  },
  {
    path: "admin/dashboard/Commission/Setcom/:id",
    exact: true,
    element: <Home page={<Setcom />} />,
  },
  {
    path: "admin/dashboard/RetailerList/setretailercom/:id",
    exact: true,
    element: <Home page={<SetRetailerCom />} />,
  },
  {
    path: "admin/dashboard/MultiList/setmulticom/:id",
    exact: true,
    element: <Home page={<SetMultiCom />} />,
  },
  {
    path: "admin/dashboard/AllReport",
    exact: true,
    element: <Home page={<AllReport />} />,
  },
  {
    path: "admin/dashboard/Commission",
    exact: true,
    element: <Home page={<Commission />} />,
  },
  {
    path: "admin/dashboard/FundTransfer",
    exact: true,
    element: <Home page={<FundTransfer />} />,
  },
  {
    path: "admin/dashboard/TodayReport",
    exact: true,
    element: <Home page={<TodayReport />} />,
  },
  {
    path: "admin/dashboard/TdComplaints",
    exact: true,
    element: <Home page={<TdComplaints />} />,
  },
  {
    path: "admin/dashboard/AllComplaints",
    exact: true,
    element: <Home page={<AllComplain />} />,
  },
  {
    path: "/AdminLogin",
    exact: true,
    element: <AdminLogin />,
  },
  {
    path: "/",
    exact: true,
    element: <Login />,
  },
  {
    path: "/retailer",
    exact: true,
    element: <RtApiDoc />,
  },
  // {
  //   path: "/retailer",
  //   exact:true,
  //   element:<Retailer/>
  // },
  // {
  //   path: "/retailer/report",
  //   exact:true,
  //   element:<Report/>
  // },
  {
    path: "/multilogin",
    exact: true,
    element: <MultiLogin />,
  },
  {
    path: "/multi/Home",
    exact: true,
    element: <MultiHome page={<MultiDashboard />} />,
  },
  {
    path: "/multi/dashboard/TodayReport",
    exact: true,
    element: <MultiHome page={<MultiTodayReport />} />,
  },
  {
    path: "/multi/dashboard/AllReport",
    exact: true,
    element: <MultiHome page={<MultiAllReport />} />,
  },
  {
    path: "/multi/dashboard/FundReport",
    exact: true,
    element: <MultiHome page={<MultiFundReport />} />,
  },
  {
    path: "/multi/dashboard/TdComplaints",
    exact: true,
    element: <MultiHome page={<MultiTdComplaints />} />,
  },
  {
    path: "/multi/dashboard/AllComplaints",
    exact: true,
    element: <MultiHome page={<MultiAllComplain />} />,
  },
  {
    path: "/multi/dashboard/ApiDoc",
    exact: true,
    element: <MultiHome page={<ApiDoc />} />,
  },
  {
    path: "*",
    element: <h2>Not found </h2>,
  },
];

export default routes;
