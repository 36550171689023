import React from 'react'

export const MainSide = (props) => {
  return (
    <>
    <div style={{marginLeft: "230px" , marginTop:"20px"}} className="container sideDiv">
     {props.page}
    </div>
    </>
  )
}
